import axios from "axios";
import Login from "layout/Login";
import { Outlet, useNavigate } from "react-router-dom";

export default function Authenticate() {
    // 시세조회 화면 접속 전 사용자 권한 체크
    const nav = useNavigate();
    let userId = sessionStorage.getItem("userId");
    let accessToken = sessionStorage.getItem("accessToken");
    if (!userId || !accessToken) nav("/login");

    axios.get(process.env.REACT_APP_BACKEND_SERVER_URL + '/api/check',
    {
        headers: {Authorization: 'Bearer ' + accessToken}
    }).then((res) => {
        const status = res.status;
        if (status !== 200) {
            sessionStorage.removeItem("userId");
            sessionStorage.removeItem("accessToken");
            userId = null;
            accessToken = null;
        }
    }).catch(e => {
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("accessToken");
        userId = null;
        accessToken = null;
    })

    // 없을 시 로그인 창으로 리다이렉트
    if (userId && accessToken) return <Outlet />
    return <Login />
}