import {BuildingDto, JYBDto, SiseDto} from "../dto/Dto";
import {convertBaseYmPlus, convertToLocalString} from "../utils/Utils";
import {useEffect, useState} from "react";

interface Props {
    buildingInf: BuildingDto,
    latestSise: SiseDto,
    jybList: JYBDto[]
}

export default function AddrInfo ({
    buildingInf,
    latestSise,
    jybList
}:Props) {

    const [privateArea, setPrivateArea] = useState<JYBDto[]>([]);
    const [privateTotArea, setPrivateTotArea] = useState<number>(0);
    const [publicArea, setPublicArea] = useState<JYBDto[]>([]);
    const [publicTotArea, setPublicTotArea] = useState<number>(0);

    useEffect(() => {
        if (!jybList || jybList.length === 0) return;

        let priv = jybList.filter((jyb) => {
            return jyb.divisionCode === '1';
        })
        let pub = jybList.filter((jyb) => {
            return jyb.divisionCode === '2';
        })
        let sumPrivateArea = 0;
        let sumPublicArea = 0;

        setPrivateArea(priv);

        priv.map((jyb) => {
            sumPrivateArea += jyb.area;
        });
        setPrivateTotArea(sumPrivateArea);
        setPublicArea(pub);
        pub.map((jyb) => {
            sumPublicArea += jyb.area;
        });
        setPublicTotArea(sumPublicArea);
    }, [jybList])

    return (
        <div className="areaInfoBody lineHeight-140">
            <table>
                <colgroup>
                    <col width="10%"/>
                    <col width="12%"/>
                    <col width="20%"/>
                    <col width="10%"/>
                    <col width="22%"/>
                </colgroup>
                <tbody>
                    <tr>
                        <td className="fs16 fw600 thead lineHeight-14" rowSpan={2}>
                            <div>주소</div>
                        </td>
                        <td colSpan={2}>
                            <div className="addr">
                                <div>도로명</div>
                                <div>
                                    {
                                        buildingInf.roadAddress ? buildingInf.roadAddress : ' - '
                                    }
                                    {
                                        buildingInf.ho ? `(${buildingInf.ho})` : ''
                                    }
                                </div>
                            </div>
                        </td>
                        <td className="fs16 fw600 thead lineHeight-14" rowSpan={2}>
                            <div>주택명</div>
                        </td>
                        <td rowSpan={2}>
                            <div>
                                {
                                    buildingInf.houseName ? buildingInf.houseName : ' - '
                                }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div className="addr">
                                <div>지번</div>
                                <div>
                                    {
                                        buildingInf.landAddress ? buildingInf.landAddress : ' - '
                                    }
                                    {
                                        buildingInf.ho ? `(${buildingInf.ho})` : ''
                                    }
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="fs16 fw600 thead">
                            <div>
                                <div>시세</div>
                                <div>(1㎡당 시세)</div>
                            </div>
                        </td>
                        <td colSpan={2}>
                            {
                                latestSise ?
                                    <div className="siseInfo">{convertToLocalString(latestSise.marketPrice)} 천원
                                        ({convertToLocalString(Math.floor(latestSise.marketPrice / latestSise.privateArea))} 천원/㎡)
                                    </div>
                                    : <div></div>
                            }
                            {
                                latestSise ?
                                    <div>시세 배포월 : {convertBaseYmPlus(latestSise.standardYm)}</div>
                                    : <div></div>
                            }
                        </td>
                        <td className="fs16 fw600 thead">
                            <div>
                                <div>공동주택</div>
                                <div>공시가격</div>
                            </div>
                        </td>
                        <td className="printDisplayNone">
                            <div><a href="http://www.realtyprice.kr/notice/town/searchPastYear.htm" target="_blank" title="새창 열림">부동산 공시가격 알리미</a></div>
                        </td>
                    </tr>
                    <tr>
                        <td className="fs16 fw600 thead lineHeight-14" rowSpan={privateArea.length === 0 ? 2 : privateArea.length + 1}>
                            <div>전용면적</div>
                        </td>
                        <td colSpan={2}>
                            <div>{
                                privateArea.length > 0 ?
                                `${privateArea[0].purposeName}/${privateArea[0].etcPurposeName}` : ' - '}</div>
                        </td>
                        <td>
                            <div>{
                                privateArea.length > 0 ?
                                    convertToLocalString(privateArea[0].area) : ' - '
                                }㎡
                            </div>
                        </td>
                        <td>
                            <div>{
                                privateArea.length > 0 ?
                                    privateArea[0].floorName : ' - '
                            }
                            </div>
                        </td>
                    </tr>
                    {
                        privateArea.filter((priv, index) => {
                            return index > 0;
                        }).map((priv, index) => (
                            <tr key={index}>
                                <td colSpan={2}>
                                    <div>{priv.purposeName}/{priv.etcPurposeName}</div>
                                </td>
                                <td>
                                    <div>{convertToLocalString(priv.area)}㎡</div>
                                </td>
                                <td>
                                    <div>{priv.floorName}</div>
                                </td>
                            </tr>
                        ))
                    }
                    <tr>
                        <td>
                            <div>면적합산</div>
                        </td>
                        <td colSpan={3}>
                            <div>{convertToLocalString(privateTotArea)}㎡</div>
                        </td>
                    </tr>

                    <tr>
                        <td className="fs16 fw600 thead lineHeight-14" rowSpan={publicArea.length === 0 ? 2 : publicArea.length + 1}>
                            <div>공용면적</div>
                        </td>
                        <td colSpan={2}>
                            <div>
                            {
                                publicArea.length > 0 ?
                                    `${publicArea[0].purposeName}/${publicArea[0].etcPurposeName}` : ' - '
                            }
                            </div>
                        </td>
                        <td>
                            <div>
                                {
                                    publicArea.length > 0 ?
                                        convertToLocalString(publicArea[0].area) : ' - '
                                }㎡
                            </div>
                        </td>
                        <td>
                            <div>
                                {
                                    publicArea.length > 0 ?
                                        publicArea[0].floorName : ' - '
                                }
                            </div>
                        </td>
                    </tr>
                    {
                        publicArea.filter((pub, index) => {
                            return index > 0;
                        }).map((pub, index) => (
                            <tr key={index}>
                                <td colSpan={2}>
                                    <div>{pub.purposeName}/{pub.etcPurposeName}</div>
                                </td>
                                <td>
                                    <div>{convertToLocalString(pub.area)}㎡</div>
                                </td>
                                <td>
                                    <div>{pub.floorName}</div>
                                </td>
                            </tr>
                        ))
                    }
                    <tr>
                        <td>
                            <div>면적합산</div>
                        </td>
                        <td colSpan={3}>
                            <div>{convertToLocalString(publicTotArea)}㎡</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}