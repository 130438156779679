import {useRef, useState} from "react";
import Container from "./Container";
import Footer from "./Footer";
import Modal from "react-modal";
import ReactModal from "react-modal";
import close from "../assets/vector.svg";
import {emailCheck, openSwalAlertPopUp} from "utils/Utils";
import {postInquiry} from "service/VillaAptService";
import LoadingModal from "../components/LoadingModal";

interface Props {

}

export default function Main({}: Props) {
  const [isInquiryOpen, setIsInquiryOpen] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const inquiryRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);

  const inquiryBtnClickEvt = () => {
    setIsInquiryOpen(true);
  }

  const replacePage = () => {
    window.location.reload();
  }

  const closeModal = () => {
    setIsInquiryOpen(false);
  }

  const inquiryModalStyle: ReactModal.Styles = {
    overlay: {
      backgroundColor: " rgba(0, 0, 0, 0.4)",
      width: "100%",
      height: "100vh",
      zIndex: "10",
      position: "fixed",
      top: "0",
      left: "0",
    },
    content: {
      width: "90rem",
      display: "flex",
      flexDirection: "column",
      gap: "3.2rem",
      height: "73.9rem",
      zIndex: "150",
      position: "absolute",
      padding: "0",
      border: "none",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.25)",
      backgroundColor: "white",
      overflow: "auto",
    },
  }

  // emailCheck
  const sendInquiryClickEvt = () => {
    if (!emailRef.current?.value || emailRef.current.value === '') openSwalAlertPopUp("CHECK_EMPTY_EMAIL");
    else if (!emailCheck(emailRef.current.value)) openSwalAlertPopUp("CHECK_REGEXP_EMAIL");
    else if (!inquiryRef.current?.value || inquiryRef.current.value === '') openSwalAlertPopUp("CHECK_INQUIRY_CONTENTS");
    else {
      postInquiry({
        email: emailRef.current.value,
        message: inquiryRef.current.value
      });
      openSwalAlertPopUp("SEND_INQUIRY_SUCCESS");
      // TO-DO 문의 실패 예외 처리
      emailRef.current.value = '';
      inquiryRef.current.value = '';
      closeModal();
    }
  };

  return (
    <div className="main">
      {loading && <LoadingModal/>}
      <Container replacePage={replacePage} setLoading={setLoading}/>
      <Modal isOpen={isInquiryOpen} onRequestClose={closeModal} ariaHideApp={false} style={inquiryModalStyle}>
        <div className="modalTopArea fs17">
          <span>연립다세대 시세조회 서비스에 대해 질문 및 의견제시가 있으시면 문의해주세요.</span>
          <span className="closeBtn pointer"><img src={close} alt="" onClick={closeModal}/></span>
        </div>
        <div className="modalCenterArea fs17 fw500">
          <div className="inquiryEmailInputArea">
            <div className="labelArea">
              <div>이메일 주소</div>
              <div>*</div>
            </div>
            <div><input type="text" ref={emailRef} className="inquiryEmailInput fs17 fw500"
                        placeholder="답변 받으실 이메일 주소를 남겨주세요."/></div>
          </div>
          <div className="inquiryContentInputArea">
            <div className="labelArea">
              <div>문의 내용</div>
              <div>*</div>
            </div>
            <div><textarea ref={inquiryRef} spellCheck="false" className="inquiryContentInput fs17 fw500"
                           placeholder="문의하실 내용을 남겨주세요."/></div>
          </div>
        </div>
        <div className="modalBottomArea">
          <button className="sendInquiryBtn fs20 pointer btn bgPrimary" onClick={sendInquiryClickEvt}>문의하기</button>
        </div>
      </Modal>
      <Footer
        isInquiryOpen={isInquiryOpen}
        onClickBtn={inquiryBtnClickEvt}
        replacePage={replacePage}
      />
    </div>
  )
}