interface Props {
    subjectList: string[],
    data: string[]
}

export default function DescriptionList ({
    subjectList,
    data
}:Props) {
    return (
        <div className="descBody">
            {
                subjectList.map((subject, index) => (
                    <div className="fs16 fw500 lineHeight-140" key={index}>
                        <div>{subject}</div>
                        <div>:</div>
                        <div>{data[index] ? data[index] : ' - '}</div>
                    </div>
                ))
            }
        </div>
    );
}