import React, {useEffect, useRef, useState} from "react";
import {get단지동호, get본건거래, get시세거래, get주소정보} from "service/VillaAptService";
import SelectBox from "../components/SelectBox";
import {AddressDto, AptDto, BuildingDto, JYBDto, SiseDto, TotSiseDto, TradeDto} from "dto/Dto";
import {convertBaseYm, convertToLocalString, getDanjiList, getDongList, getHoList, getSelectedApt, openSwalAlertPopUp} from "utils/Utils";
import {useNavigate} from "react-router-dom";
import logo from "../assets/logo.svg";
import ItemTitle from "../components/ItemTitle";
import NaverMap from "../components/NaverMap";
import BuildingInfo from "../components/BuildingInfo";
import AddrInfo from "../components/AddrInfo";
import AreaChart from "../components/AreaChart";
import ScatterChart from "../components/ScatterChart";
import AddressModal from "../components/AddressModal";

interface Props {
  replacePage: () => void
  setLoading: (loading: boolean) => void
}

export default function Container({
                                    replacePage,
                                    setLoading
                                  }: Props) {
  const nav = useNavigate();

  // 주소 검색
  const [pnu, setPnu] = useState("");
  const [addressList, setAddressList] = useState<AddressDto[]>([]);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [result, setResult] = useState('');
  const newAddressInputRef = useRef<HTMLInputElement>(null);

  // 단지/동/호 정보
  const [aptInfo, setAptInfo] = useState<AptDto[]>([]);

  // selectbox 텍스트 리스트
  const [danjiList, setDanjiList] = useState<string[]>([]);
  const [dongList, setDongList] = useState<string[]>([]);
  const [hoList, setHoList] = useState<string[]>([]);

  // 본건 정보
  const [buildingInf, setBuildingInf] = useState<BuildingDto>({
      areaJigu: "",
      baseYm: "",
      buildingArea: 0,
      dong: "",
      etcPurposeName: "",
      floor: "",
      gprate: 0,
      groundFloor: 0,
      groundTotalArea: 0,
      high: 0,
      ho: "",
      houseName: "",
      jpk: "",
      landAddress: "",
      landArea: 0,
      mainHouseDivisionName: "",
      mainPerposeName: "",
      parkingCount: 0,
      pnu: "",
      roadAddress: "",
      sedeCount: 0,
      totalArea: 0,
      undergroundFloor: 0,
      useApprovalDate: "",
      yjrate: 0,
      lat: 0, lng: 0
    }
  );

  const [jybList, setJybList] = useState<JYBDto[]>([]);
  // 시세 리스트
  const [siseList, setSiseList] = useState<SiseDto[]>([]);
  // 주변 사레 리스트
  const [tradeList, setTradeList] = useState<TradeDto[]>([]);
  // 본건 거래 리스트
  const [mainTradeList, setMainTradeList] = useState<TradeDto[]>([]);

  // 선택 단지, 동, 호 텍스트
  const [selectedDanji, setSelectedDanji] = useState('');
  const [selectedDong, setSelectedDong] = useState('');
  const [selectedHo, setSelectedHo] = useState('');

  // 최종 선택 단지, 동, 호
  const [selectedJpk, setSelectedJpk] = useState<AptDto>();

  // 단지동호 팝업 열림
  const [isAddrDetailHide, setIsAddrDetailHide] = useState(true);

  // 본건 시세/주변거래사례 테이블 숨김 처리
  const [isSiseHide, setIsSiseHide] = useState(true);

  // 단지 선택 이벤트
  const selectDanjiEvt = (danji: string) => {
    setSelectedDanji(danji);
    setSelectedDong('');
    setSelectedHo('');
    setDongList(getDongList(aptInfo, danji));
  }

  // 동 선택 이벤트
  const selectDongEvt = (dong: string) => {
    setSelectedDong(dong);
    setSelectedHo('');
    setHoList(getHoList(aptInfo, selectedDanji, dong));
  }

  // 호 선택 이벤트
  const selectHoEvt = (ho: string) => {
    let selectedApt = getSelectedApt(aptInfo, selectedDanji, selectedDong, ho);
    if (ho !== selectedHo) {
      setIsSiseHide(true);
    }
    setSelectedHo(ho);
    setSelectedJpk(selectedApt);
  }

  // 주소 검색 이벤트
  const onKeyDownNewAddressEvent = (e: any) => {
    if (e.key === "Backspace"
      && e.target.value === ''
      && newAddressInputRef.current !== null) {
      newAddressInputRef.current.focus();
    }
    else if (e.key === "Enter") {
      // e.stopPropagation();
      e.preventDefault();   // 이거 없으면 3자리 미만 입력하고 엔터 시, 메시지 바로 사라짐
      searchNewAddress();
    }
  }

  const searchNewAddress = () => {
    // 주소는 최소 3자리 이상 입력
    if (keyword.length < 3) {
      openSwalAlertPopUp("INVALID_SEARCH_INFO");
      return;
    }
    // 주소 검색 시작
    setLoading(true);
    get주소정보(keyword).then((res) => {
      // 빌라 데이터가 없으면 알림 창 띄우고 끝냄
      if (res.length === 0) {
        openSwalAlertPopUp("NOT_EXISTS_BILLA_DATA");
        return;
      }
      setAddressList(res);
      setOpenAddressModal(true);
    }).finally(() => {
      setLoading(false);
    });
  }

  // 단지/동/호 검색 클릭 이벤트
  const searchDanjiDongHoBtnEvt = () => {
    // state 초기화
    setDanjiList([]);
    setDongList([]);
    setHoList([]);
    setSiseList([]);
    setTradeList([]);
    setSelectedDanji('');
    setSelectedDong('');
    setSelectedHo('');
    setSelectedJpk({} as AptDto);
    setIsSiseHide(true);
    setIsAddrDetailHide(true);

    // 첫 진입시, 검색 결과 없다고 창 안 뜨게
    if (keyword === "") {
      return;
    }
    setLoading(true);
    get단지동호(pnu)
      .then((res: AptDto[]) => {
        if (res.length === 0) {
          openSwalAlertPopUp("NOT_EXISTS_APT_DATA");
        }
        else {
          setAptInfo(res);
          let danjiList = getDanjiList(res);
          let dongList = getDongList(res, danjiList[0]);
          let hoList = getHoList(res, danjiList[0], dongList[0]);
          setDanjiList(danjiList);

          // 단지, 동 리스트가 1개일 경우 selected
          if (danjiList.length === 1) {
            setSelectedDanji(danjiList[0]);
            setDongList(dongList);
          }
          if (dongList.length === 1) {
            const selectedJpk = getSelectedApt(res, danjiList[0], dongList[0], hoList[0]);
            setSelectedDong(dongList[0]);
            setSelectedHo(hoList[0]);
            setSelectedJpk(selectedJpk);
            setHoList(hoList);
          }

          setIsAddrDetailHide(false);
        }
      }).catch(e => {
      if (e.response !== undefined && e.response.status === 403) {
        nav("/login");
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  // 시세 검색 클릭 이벤트
  const onClickSearchSiseBtnEvt = () => {
    // 호는 선택했지만, JPK가 없으면 에러 메시지 출력
    if (!selectedJpk) {
      openSwalAlertPopUp("NOT_EXISTS_JPK_DATA");
      return;
    }
    if (selectedDanji !== ''
      && selectedDong !== ''
      && selectedHo !== ''
    ) {
      setLoading(true);
      get시세거래(selectedJpk).then((res: TotSiseDto) => {
        // 시세 없으면 알림창 띄움
        if (res.siseList.length === 0) {
          openSwalAlertPopUp("NOT_EXISTS_SISE_DATA");
          return;
        }
        setIsSiseHide(false);
        setSiseList(res.siseList);
        setTradeList(res.tradeList);
        setBuildingInf(res.buildingInfo);
        setJybList(res.jybList);
        getMainTradeList(res.siseList[0]);
      }).catch(e => {
        if (e.response !== undefined && e.response.status === 403) {
          nav("/login");
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  const getMainTradeList = (siseDto: SiseDto) => {
    setLoading(true);
    get본건거래(siseDto).then((res: TradeDto[]) => {
      if (res.length === 0) {
      }

      setMainTradeList(res);
    }).catch(e => {
      if (e.response !== undefined && e.response.status === 403) {
        nav("/login");
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    searchDanjiDongHoBtnEvt();
  }, [pnu]);

  return (
    <div className="container fw400">
      {/* 헤더 */}
      <div className="header">
        <div>
          <img className="pointer" src={logo} alt="logo" onClick={replacePage}/>
        </div>
        <div className="fs15 fw400 primary">서울∙경기∙인천 지역의 연립∙다세대 호별 시세 조회!</div>
        <div className="title">주소기반 <span className="primary bold">연립∙다세대</span> <span
          className="bold">시세조회</span></div>
      </div>
      {/* 주소 검색 */}
      <div className="selectAddrGroup">
        <input ref={newAddressInputRef} type="text" className="search-address" value={keyword}
               placeholder="검색할 주소를 입력해주세요." name="address-search"
               onChange={(e) => setKeyword(e.target.value)} onKeyDown={onKeyDownNewAddressEvent}/>
        {/* 주소 검색시 뜨는 모달창 */}
        {openAddressModal ?
          <AddressModal addressList={addressList}
                        setOpenAddressModal={setOpenAddressModal}
                        setPnu={setPnu}
                        setKeyword={setKeyword}
                        setResult={setResult}
          /> : null}
        <div className="searchBtn pointer btn bgPrimary" onClick={searchNewAddress}>
          <img src={"search.svg"} alt=""/>
        </div>
      </div>
      <div className="print-address">{result}</div>
      {/* 단지 / 동 / 호 선택박스 */}
      <div className={`selectAddrDetailGroup ${isAddrDetailHide ? 'hide' : ''}`}>
        <SelectBox title={'단지'} data={danjiList} onSelected={selectDanjiEvt}/>
        <SelectBox title={'동'} data={dongList} onSelected={selectDongEvt}/>
        <SelectBox title={'호'} data={hoList} onSelected={selectHoEvt}/>
        <div className="btn bgPrimary siseBtn fs19 bold" onClick={() => {
          onClickSearchSiseBtnEvt()
        }}>
          <div><img src={"siseBtn.svg"} alt=""/></div>
          <div>
            <div>시세 확인</div>
          </div>
        </div>
      </div>
      {/* 시세 확인 시 보이는 부분, selectedAddrSiseInfo 바로 하위 div 태그들은 프린트 시, page-break 역할 */}
      <div className={`selectedAddrSiseInfo ${isSiseHide ? 'hide' : ''}`}>
        {/* 네이버 지도, 건축물 정보 */}
        <div>
          <ItemTitle title={'물건 기본 정보'}/>
          <NaverMap lat={buildingInf.lat} lng={buildingInf.lng}/>
          <BuildingInfo buildingInf={buildingInf}/>
        </div>
        {/* 본건 정보 */}
        <div>
          <AddrInfo
            buildingInf={buildingInf}
            latestSise={siseList[0]}
            jybList={jybList}
          />
          <ItemTitle title={'최근 2년간 본건 시세 추이'}/>
          <AreaChart siseList={siseList}/>
          {/*본건 시세 테이블*/}
          <div>
            <table className={`selectedAddrSiseInfoTable`}>
              <thead className="fs15 fw500">
              <tr>
                <th>데이터기준월</th>
                <th>단지명</th>
                <th>동명</th>
                <th>호명</th>
                <th>건축물유형</th>
                <th>전용면적(㎡)</th>
                <th>시세가격(천원)</th>
              </tr>
              </thead>
              <tbody className="fs17 fw400">
              {
                siseList.map((item: SiseDto, key) => {
                  return (
                    <tr key={key}>
                      <td>{convertBaseYm(item.standardYm)}</td>
                      <td title={item.danjiName}>{item.danjiName}</td>
                      <td title={item.dong}>{item.dong}</td>
                      <td>{item.ho}</td>
                      <td>{item.chkViewInfo}</td>
                      <td>{convertToLocalString(item.privateArea)}</td>
                      <td className="bold">{convertToLocalString(item.marketPrice)}</td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {/* 본건 거래사례 scatter 차트 */}
          {mainTradeList.length > 0 ?
            <>
              <ItemTitle title={'본건 거래 리스트'}/>
              <ScatterChart mainTradeList={mainTradeList}/>
            </>
            : <></>
          }
          {/*거래사례 테이블*/}
          <ItemTitle title={'주변 거래 사례'}/>
          <table className={`selectedAddrSrInfoTable_holo`}>
            <thead className="fs14 fw500">
            <tr>
              <th>지번주소</th>
              <th>거래시기</th>
              <th>사례종류</th>
              <th>실거래가(천원)</th>
              <th>건축년도</th>
              <th>층</th>
              <th>전용면적(㎡)</th>
              <th>대지권면적(㎡)</th>
              <th>본건과거리(m)</th>
              <th>단가(천원/㎡)</th>
            </tr>
            </thead>
            <tbody className="fs16 fw400">
            {
              tradeList.map((item: TradeDto, key) => {
                return (
                  <tr key={key}>
                    <td title={item.landAddress}>{item.landAddress}</td>
                    <td>{convertBaseYm(item.tradeYm)}</td>
                    <td>{(item.tradeCase === '나홀로') ? '나홀로아파트' : item.tradeCase}</td>
                    <td>{convertToLocalString(item.actualPrice)}</td>
                    <td>{item.trdConYear}</td>
                    <td>{item.floor}</td>
                    <td>{convertToLocalString(item.exclusiveArea)}</td>
                    <td>{convertToLocalString(item.landArea)}</td>
                    <td>{convertToLocalString(item.distance)}</td>
                    <td>{convertToLocalString(item.unitPrice)}</td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}