import {AptDto} from "dto/Dto";
import Swal from "sweetalert2";
import bcrypt from "bcryptjs";

// Alert 문구
const caseList: { [id: string]: string } = {
  EMPTY_ID: "아이디는 공백이 될 수 없습니다.",
  EMPTY_PWD: "비밀번호는 공백이 될 수 없습니다.",
  INVALID_USER_INFO: "회원 정보가 올바르지 않습니다.",
  NOT_EXISTS_APT_DATA: "검색 결과가 존재하지 않습니다.",
  NOT_EXISTS_SISE_DATA: "조회된 시세가 존재하지 않습니다.",
  NOT_EXISTS_JPK_DATA: "해당 호에 대한 정보가 없습니다.",
  NOT_EXISTS_MAIN_TRADE_DATA: "본건거래사례가 존재하지 않습니다.",
  NOT_EXISTS_JUBYUN_DATA: "주변거래사례가 존재하지 않습니다.",
  NOT_EXISTS_BILLA_DATA: "빌라 정보가 존재하지 않습니다.",
  INVALID_SEARCH_INFO: "3자 이상 입력해주세요.",
  UNAUTHORIZED: "조회 권한이 없습니다.",
  ERR_NETWORK: "알 수 없는 오류가 발생했습니다.",
  CHECK_EMPTY_EMAIL: "이메일을 입력해주세요.",
  CHECK_REGEXP_EMAIL: "올바른 이메일 양식이 아닙니다.",
  CHECK_INQUIRY_CONTENTS: "문의 내용을 입력해주세요.",
  SEND_INQUIRY_SUCCESS: "문의 내용이 접수되었습니다.",
  SEND_INQUIRY_FAILED: "전송 중 알 수 없는 오류가 발생했습니다."
}

/**
 * 빈 Object 체크
 * @param obj 오브젝트
 * @returns true or false
 */
export function isEmpty(obj: Object) {
  return Object.keys(obj).length === 0;
}

/**
 * 기준일자 문자열 생성 ex) 20231111 -> 2023.11.11
 * @param baseDt 기준일자
 * @returns
 */
export function convertBaseDt(baseDt: string) {
  if (baseDt === null || baseDt === '' || baseDt === undefined) return '';
  return baseDt.substring(0, 4) + '.' + (baseDt.substring(4, 6)) + '.' + (baseDt.substring(6, 8));
}

/**
 * 기준년월 문자열 생성 ex) 202311 -> 2023.11
 * @param baseYm 기준년월
 * @returns
 */
export function convertBaseYm(baseYm: string) {
  if (baseYm === null || baseYm === '' || baseYm === undefined) return '';
  return baseYm.substring(0, 4) + '.' + (baseYm.substring(4, 6));
}

/**
 * 기준년월 문자열 생성 ex) 202311 -> 2023.12
 * api데이터는 전문생성기준월이라 시세배포월은 +1 처리함
 * @param baseYm 기준년월
 * @returns
 */
export function convertBaseYmPlus(baseYm: string) {
  if (baseYm === null || baseYm === '' || baseYm === undefined) return '';

  let month = Number(baseYm.substring(4, 6)) + 1;
  let year = Number(baseYm.substring(0, 4));
  if (month > 12) {
    return year + 1 + '.01';
  } else {
    return year + '.' + ('0' + month).slice(-2);
  }
}

/**
 * 기준년월 문자열 생성 ex) 202311 -> 2023년 12월 기준
 * api데이터는 전문생성기준월이라 데이터기준월로 +1 처리함
 * @param baseYm 기준년월
 * @returns
 */
export function convertBaseYmAsKR(baseYm: string) {
  if (baseYm === null || baseYm === '' || baseYm === undefined) return '';
  let month = Number(baseYm.substring(4, 6)) + 1;
  let year = Number(baseYm.substring(0, 4));
  if (month > 12) {
    return year + 1 + "년 1월 기준";
  } else {
    return year + "년 " + month + "월 기준";
  }
}

/**
 * 기준년월 문자열을 timestamp로 치환
 *
 * @param baseYm 기준년월
 * @returns
 */
export function convertStrToDate(dateStr:string) {
  return Date.UTC(Number(dateStr.substring(0,4)), Number(dateStr.substring(4,6)) - 1, 1);
}

/**
 * timestamp를 문자열로 다시 변경
 *
 * @param baseYm 기준년월
 * @returns
 */
export function makeDisplayYearMonthByDateUTC(dateUTC:any) {
  const date = new Date(dateUTC);
  const month = date.getMonth() + 1;

  if (month < 10)
  {
    return date.getFullYear() + ".0" +  month;
  }

  return date.getFullYear() + '.' +  month;
}

/**
 * 원 단위 콤마 문자열 생성 ex) 2000000000 -> 2,000,000,000
 * @param value
 * @returns
 */
export function convertToLocalString(value: number) {
  return (value === null || value === 0 || value === undefined) ? ' - ' : (+value).toLocaleString('ko-kr');
}

/**
 * 사례 종류 문자열 변경 ex) 연립다세대 -> 연립, 나홀로아파트 -> 나홀로
 * @param value
 * @returns
 */
export function convertTradeCase(value: string) {
  if (value === null || value === '' || value === undefined) return '';
  return (value === '연립다세대') ? '연립' : '나홀로';
}

/**
 * 열람 지역 문자열 생성
 * @param sidoName 시도명
 * @param aptInfo AptDto
 * @returns
 */
export function getViewingArea(sidoName: string, aptInfo: AptDto) {
  let land = (aptInfo.landAddress) ? aptInfo.landAddress : '';
  let road = (aptInfo.roadAddress) ? aptInfo.roadAddress.substring(sidoName.length + 1) : '';
  let combinedAddr = (land !== '' && road !== '') ? land + '(' + road + ')' : '';
  return combinedAddr;
}

/**
 * 단지 리스트 필터 리스트 (텍스트)
 * @param res AptDto[]
 * @returns string[]
 */
export function getDanjiList(res: AptDto[]) {
  return Array.from(
      new Set<string>(res.map((item: AptDto) => {
        return item.danjiName
      }))
  );
}

/**
 * 동 리스트 필터 리스트 (텍스트)
 * @param res AptDto[]
 * @param danjiName 단지명
 * @returns string[]
 */
export function getDongList(res: AptDto[], danjiName: string) {
  return Array.from(
      new Set<string>(
          res.filter((item: AptDto) => (item.danjiName === danjiName))
          .map(((item: AptDto) => {
            return item.dong
          }))
      )
  );
}

/**
 * 호 리스트 필터 리스트 (텍스트)
 * @param res AptDto[]
 * @param danjiName 단지명
 * @param dongName 동명
 * @returns string[]
 */
export function getHoList(res: AptDto[], danjiName: string, dongName: string) {
  return Array.from(
      new Set<string>(
          res.filter((item: AptDto) => (item.danjiName === danjiName) && (item.dong === dongName))
          .map((item: AptDto) => {
            return item.ho
          })
      )
  );
}

/**
 * 최종 선택된 AptDto 조회
 * @param res AptDto[]
 * @param danjiName 단지명
 * @param dongName 동명
 * @param hoName
 * @returns AptDto
 */
export function getSelectedApt(res: AptDto[], danjiName: string, dongName: string, hoName: string) {
  return res.find((item: AptDto) => (item.danjiName === danjiName) && (item.dong === dongName) && (item.ho === hoName));
}

/**
 * Alert창 생성
 * @param caseName Alert 타입
 */
export function openSwalAlertPopUp(caseName: string) {
  let title = caseList[caseName];
  if (!title) title = '';
  Swal.fire({
    title: title,
    confirmButtonColor: "#2D71C4"
  });
}

/**
 * 비밀번호 단방향 암호화 (Bcrypt)
 * @param password
 * @returns
 */
export function getBcryptPassword(password: string) {
  try {
    const salt = bcrypt.genSaltSync(10);
    return bcrypt.hashSync(password, salt);
  } catch (e) {
    return password;
  }
}

/**
 * 이메일 유효성 체크
 * @param email
 * @returns
 */
export function emailCheck(email: string) {
  const email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
  if (email_regex.test(email)) return true;
  return false;
}

/**
 * 로딩창 표시
 */
// export function nowLoading(flag:boolean) {
//   document.querySelector("#loading").style.display = flag ? "block" : "none";
// }
