import {AptDto, BuildingDto} from "../dto/Dto";
import DescriptionList from "./DescriptionList";
import {useEffect, useState} from "react";
import {convertBaseDt, convertToLocalString} from "../utils/Utils";

interface Props {
    buildingInf: BuildingDto
}

export default function BuildingInfo ({buildingInf}:Props) {

    const [buildingPurpose, setBuildingPurpose] = useState<string[]>([]);
    const [buildingArea, setBuildingArea] = useState<string[]>([]);
    const [buildingCompose, setBuildingCompose] = useState<string[]>([]);
    const [buildingApproval, setBuildingApproval] = useState<string[]>([]);

    useEffect(() => {
        if (!buildingInf) return;

        let parkingPerSede = buildingInf.parkingCount > 0 ? Math.round(buildingInf.sedeCount / buildingInf.parkingCount * 100) / 100 : 0.00;

        setBuildingPurpose([
            buildingInf.mainPerposeName,
            buildingInf.etcPurposeName,
            buildingInf.mainHouseDivisionName
        ]);
        setBuildingArea([
            convertToLocalString(buildingInf.landArea) + '㎡',
            `${convertToLocalString(buildingInf.buildingArea)}㎡ (건페율 ${convertToLocalString(buildingInf.gprate)}%)`,
            convertToLocalString(buildingInf.totalArea) + '㎡',
            `${convertToLocalString(buildingInf.groundTotalArea)}㎡ (용적률 ${convertToLocalString(buildingInf.yjrate)}%)`
        ]);
        setBuildingCompose([
            convertToLocalString(buildingInf.high) + 'm',
            `지상 ${buildingInf.groundFloor}층, 지하 ${buildingInf.undergroundFloor}층`,
            convertToLocalString(buildingInf.sedeCount) + '세대',
            `총 ${convertToLocalString(buildingInf.parkingCount)}대 (세대당 ${convertToLocalString(parkingPerSede)}대)`
        ])
        setBuildingApproval([
            convertBaseDt(buildingInf.useApprovalDate),
            buildingInf.areaJigu
        ])
    }, [buildingInf])

    return (
        <div className="buildingInfoBody">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div className="fs16 fw600 lineHeight-14">용도</div>
                        </td>
                        <td>
                            <DescriptionList 
                                subjectList={['주', '부', '구분']} 
                                data={buildingPurpose}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="fs16 fw600 lineHeight-14">면적</div>
                        </td>
                        <td>
                            <DescriptionList 
                                subjectList={['대지면적', '건축면적', '연면적', '지상연면적']} 
                                data={buildingArea}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="fs16 fw600 lineHeight-14">구성</div>
                        </td>
                        <td>
                            <DescriptionList
                                subjectList={['높이', '층수', '세대수', '주차장']}
                                data={buildingCompose}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="fs16 fw600 lineHeight-14">승인</div>
                        </td>
                        <td>
                            <DescriptionList
                                subjectList={['사용승인일', '지역지구']}
                                data={buildingApproval}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}