import React, {useEffect, useState} from 'react'

interface Props {
    title: string,
    data: string[],
    onSelected: (selected: string) => void
}

/**
 * 단지, 동, 호의 선택 컴포넌트
 */
export default function SelectBox ({
    title,
    data,
    onSelected
}: Props) {
    const [clickedIndex, setClickedIndex] = useState(-1);
    useEffect(() => {
        if (data.length === 1) setClickedIndex(0);
        else if (title === '호') setClickedIndex(0);
    }, [data])

    return (
        <div className="selectBox">
            <div className="fs17 bold">{title}</div>
            <div className="selectItemList">
                {
                    data.map((item: string, key) => {
                        return (
                            <div className={`selectItem fs16 fw400 ${(clickedIndex == key) ? 'selected': ''}`}
                                 key={key}
                                 onClick={() => {
                                    onSelected(item);
                                    setClickedIndex(key);
                                 }}
                            >
                                {item}
                            </div>
                        )
                    })
                }
                
            </div>
        </div>
    );
}