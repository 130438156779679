import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from 'layout/Login';
import AuthCheck from 'templetes/AuthCheck';
import Main from 'layout/Main';

function App() {
  return (
  <>
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />}/>
        <Route element={<AuthCheck />}>
          <Route path='/' element={
          <Main />
        }/>
        </Route>
      </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
