import logo from "../assets/logo.svg"

interface Props {
    isInquiryOpen: boolean
    onClickBtn: () => void
    replacePage: () => void
}

export default function Footer({
    isInquiryOpen,
    onClickBtn,
    replacePage
}: Props) {
    return (
        <div className="footer">
            <div className="footerBody">
                <div className="footerLeft">
                    <img className="pointer" src={logo} alt="" onClick={replacePage} />
                    <div className="footerContent fs14">
                        <div className="iLoveCompany">
                            <div><b>서울사무소</b> 서울시 중구 서소문로 138 대한일보 빌딩 1205호</div>  
                            <div className="leftBorder">|</div>  
                            <div><b>T</b> 02-779-9366</div>
                            <div className="leftBorder">|</div>
                            <div><b>F</b> 02-779-9364</div>                            
                        </div>                        
                        <div className="copyRight fw300">
                            <span>COPYRIGHT 2017 BY BIGVALUE CO.LTD. ALL RIGHTS RESERVED.</span>
                        </div>
                    </div>
                </div>
                <button className="inquiryBtn pointer btn bgPrimary" onClick={onClickBtn}>문의하기</button>
            </div>
        </div>
    )
}