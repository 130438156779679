import {AptDto, SiseDto, TotSiseDto, TradeDto} from "../dto/Dto";
import {useEffect, useRef, useState} from "react";
import {
    convertBaseYmPlus,
    convertStrToDate,
    convertToLocalString,
    makeDisplayYearMonthByDateUTC,
    openSwalAlertPopUp
} from "../utils/Utils";
import Highcharts, {Chart} from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import {get본건거래} from "../service/VillaAptService";
import {useNavigate} from "react-router-dom";

declare module 'highcharts' {
    interface Point {
        floor: number;
        exclusiveArea:number;
    }
}

interface Props {
    mainTradeList: TradeDto[]
}

export default function ScatterChart ({ mainTradeList }: Props) {

    const chartRef = useRef<{
        chart: Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);
    const nav = useNavigate();
    const [chartOption, setChartOption] = useState<object>({});

    // 프린트 시, 동작하는 addEventListener
    useEffect(()=> {
        let mql = window.matchMedia("print");

        mql.addEventListener("change", () => changeWidth(mql));
        return () => mql.removeEventListener("change", () => changeWidth(mql));
    },[])

    useEffect(() => {
        if (!mainTradeList || mainTradeList.length === 0) return;

        const categoryList =  getDateFromData(mainTradeList[0].tradeYm);
        // const xAxisTickPositions = getTickPosition(categoryList);
        const data:object[] = [];

        mainTradeList.filter((tradeDto) => {
            let tradeYm = convertStrToDate(tradeDto.tradeYm.substring(0,6));

            return categoryList.includes(tradeYm)
        }).map((tradeDto, index) => {
            data.push({
                x: convertStrToDate(tradeDto.tradeYm),
                y: tradeDto.unitPrice,
                floor: tradeDto.floor,
                exclusiveArea: tradeDto.exclusiveArea
            });
        });

        setChartOption({
            title: {
                text: null
            },
            chart: {
                type: 'scatter',
                spacingLeft: 0,
                spacingRight: 0,
                width: 1200,
                height: 500
            },
            xAxis: {
                type: 'datetime',
                lineWidth : 0,
                labels: {
                    style: {
                        fontSize: '1.5rem',
                    },
                },
                gridLineWidth: 1,
                startOnTick: false,
                endOnTick: false,
                tickLength: 0,
                dateTimeLabelFormats: {
                    millisecond: '%Y. %m',
                    second: '%Y. %m',
                    minute: '%Y. %m',
                    hour: '%Y. %m',
                    day: '%Y. %m',
                    week: '%Y. %m',
                    month: '%Y. %m',
                    year: '%Y. %m'
                }
            },
            yAxis: {
                lineWidth : 0,
                title: {
                    align: 'high',
                    offset: 0,
                    text: '(단위 : 천원/㎡)',
                    style: {
                        fontSize: '15px'
                    },
                    rotation: 0,
                    x: 60,
                    y: -15
                },
                tickAmount: 6,
                gridLineDashStyle: 'longDash',
                labels: {
                    align: 'right',
                    format: '{value:.,0f}',
                    floating: false,
                    x: 0,
                    // useHTML: false,
                    style: {
                        minWidth: '2.2rem',
                        paddingLeft: '0.32rem',
                        paddingRight: '0.32rem',
                        marginLeft: '1rem',
                        marginRight: '1rem',
                        textAlign: 'right',
                        fontSize: '1.5rem',
                        zIndex: '0'
                    },
                }
            },
            plotOptions: {
                series: {
                    animation: false,
                    events: {
                        legendItemClick: function() {
                            return false;
                        },
                    },
                },
                scatter: {
                    marker: {
                        radius: 3,
                    },
                    // lineWidth: 2,
                    states: {
                        hover: {
                            lineWidth: 2
                        }
                    },
                    threshold: null
                }
            },
            legend: {
                enabled: true,
                align: 'center',
                verticalAlign : 'top',
                useHTML: false,
                itemStyle: {
                    lineHeight: '1.4rem',
                    fontSize: '1.7rem',
                },
                itemMarginTop: 0,
                itemMarginBottom: 0,
                padding : 0,
                x: 0,
                y: 0,
            },
            tooltip: {
                shadow: true,
                useHTML: true,
                // shape: null,
                formatter: function(this:Highcharts.TooltipFormatterContextObject) {
                    return `
                        <div>
                            <div class="fs12">${makeDisplayYearMonthByDateUTC(this.x)}</div>
                            <div class="fs14">
                                <table>
                                    <tr>
                                        <td>층 :</td>
                                        <td><span class="bold">${this.point.floor ? this.point.floor : '-'}</span></td>                                        
                                    </tr>
                                    <tr>
                                        <td>면적 : </td>
                                        <td><span class="bold">${this.point.exclusiveArea ? this.point.exclusiveArea : '-'}</span></td>                                        
                                    </tr>
                                    <tr>
                                        <td>가격 : </td>
                                        <td><span class="bold">${convertToLocalString(Number(this.y))}</span></td>
                                    </tr>                                    
                                </table>
                            </div>
                        </div>
                    `;
                },
                style: {
                    backgroundColor: '#FFFFFF',
                    zIndex: '10'
                }
            },
            series: [{
                type: 'scatter',
                name: '가격',
                data: data,
                color: '#17C7FF',
                legendSymbol: 'rectangle',
                marker : {
                    enabled: true,
                    symbol : 'round',
                    radius: 5,
                }
            }]
        })
    }, [mainTradeList])

    // 2년치 basYm 생성 함수
    const getDateFromData = (baseYm:string) => {
        let basYmList:any[] = [];
        let monthList = [];
        const basYm = baseYm;
        const year = Number(basYm.substring(0, 4));
        const month = Number(basYm.substring(4,6));

        for (let i=0; i<24; i++) {
            monthList.unshift(month-i);
        }

        monthList.map((mon, index) => {
            let tmpMon = mon;

            if (mon <= 0) {
                tmpMon = mon - (Math.floor(mon / 12) * 12)
                let monStr = tmpMon < 10 ? '0' + tmpMon : '' + tmpMon;
                let fixedToBasYm = (year + Math.floor(mon / 12)) + monStr;
                basYmList.push(convertStrToDate(fixedToBasYm));
            } else {
                let monStr = tmpMon < 10 ? '0' + tmpMon : '' + tmpMon;
                let fixedToBasYm = year + monStr;
                basYmList.push(convertStrToDate(fixedToBasYm));
            }
        })

        return basYmList;
    }

    // chart data로 가공하는 함수
    const setDataFillNa = (categoryList:string[]) => {
        let resData:object[] = [];

        let dataSet:number[] = [];

        categoryList.map((category) => {
            let marketPrice = 0;

            dataSet.push(marketPrice);
        })

        let modified:number[] = [];

        for (let i = 0; i < dataSet.length; i++) {
            let aver = 0;
            let indexEnd = i;

            if (dataSet[i] === 0 ) {
                for (var j = i + 1; j < dataSet.length; j++){
                    if (dataSet[j] != 0) {
                        indexEnd = j;
                        break;
                    }
                }

                if (indexEnd === i) {
                    aver = i === 0 ? dataSet[i] : dataSet[i-1];
                    indexEnd = dataSet.length
                } else {
                    aver = i === 0 ? dataSet[indexEnd] : ((dataSet[i-1] + dataSet[indexEnd]) / 2);
                }

                for (let j = i; j < indexEnd; j++) {
                    modified.push(aver);
                }

                i = indexEnd;
            }
        }

        dataSet.map((d, index) => {
            if (d === 0) {
                resData.push({
                    y: modified[index],
                    marker : {
                        fillColor: '#FFFFFF',
                        lineWidth: 1,
                        lineColor: '#a0a0a0',
                        radius: 4
                    },
                })
            } else {
                resData.push({
                    y: d,
                    marker: {
                        fillColor: '#0066E2'
                    }
                })
            }
        })

        return resData;
    }

    // 프린트할 때 차트 size 재조정하는 함수
    const changeWidth = (mql:MediaQueryList) => {

        // let mql = window.matchMedia("print");
        if (mql.matches) {
            if (chartRef.current) {
                chartRef.current.chart.setSize(1000, 500, false);
            }
        } else {
            if (chartRef.current) {
                chartRef.current.chart.setSize(1200, 500, false);
            }
        }
    }

    return (
        <div className="areaChart">
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOption}
                ref={chartRef}
            />
        </div>
    );
}