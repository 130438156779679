import Spinner from "../assets/Spinner.svg";


export default function LoadingModal() {

  return (
    <div className="loading-modal">
      <img className="loading-img" src={Spinner} alt="로딩"/>
    </div>
  )
}