import axios from "axios";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBcryptPassword, openSwalAlertPopUp } from "utils/Utils";

interface Props {

}

export default function Login({

}: Props) {
    const userInfo = "";
    const isLogin = sessionStorage.getItem("userId");
    const nav = useNavigate();
    const [userId, setUserId] = useState("");
    const [userPw, setUserPw] = useState("");
    const userIdRef = useRef<HTMLInputElement>(null);
    const userPwRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isLogin) nav("/");
    }, [userInfo]);

    // 아이디, 패스워드 입력 onChange 이벤트
    function changeValue(event: { target: { name: any; value: SetStateAction<string>; }; }) {
        const name = event.target.name;
        if (name === "password") {
            setUserPw(event.target.value);
        } else {
            setUserId(event.target.value);
        }
    }    

    // 로그인 버튼 클릭 이벤트
    function doLogin() {
        if (userId === "") {
            openSwalAlertPopUp("EMPTY_ID");
            if(userIdRef.current !== null) userIdRef.current.focus();
            return;
        }
        if (userPw === "") {
            openSwalAlertPopUp("EMPTY_PWD");
            if(userPwRef.current !== null) userPwRef.current.focus();
            return;
        }

        axios.post(process.env.REACT_APP_BACKEND_SERVER_URL + '/api/login', {
            username: userId,
            password: getBcryptPassword(userPw)
        }).then((res) => {
            const accessToken = res.data.accessToken;
            if (accessToken !== undefined) {
                sessionStorage.setItem("userId", userId);
                sessionStorage.setItem("accessToken", res.data.accessToken)
                nav('/');
            } else openSwalAlertPopUp("INVALID_USER_INFO");                
        })
        .catch(() => {
            openSwalAlertPopUp("INVALID_USER_INFO");              
        });
    }    

    return (
            <div className="Userlogin">    
                <div className="loginHeader">
                    <div className="fs17 fw400 primary">서울∙경기∙인천 지역의 연립∙다세대 호별 시세 조회!</div>
                    <div className="title">주소기반 <span className="primary bold">연립∙다세대</span> <span className="bold">시세조회</span></div>
                </div>                
                <div className="loginBox">                
                    <form className="loginForm">
                        <div className="formInput">
                            <input type="text" id="loginId" name="id" value={userId} 
                                   onChange={changeValue} placeholder="아이디를 입력해주세요"
                                   onKeyDown={(e) => {
                                        if(e.key === 'Enter') doLogin()
                                   }}
                                   />
                        </div>
                        <div className="formInput">
                            <input type="password" id="loginPassword" name="password" value={userPw} 
                                   onChange={changeValue} autoComplete="false" placeholder="비밀번호를 입력해주세요" 
                                   onKeyDown={(e) => {
                                        if(e.key === 'Enter') doLogin()
                                    }}                                   
                                   />
                        </div>                    
                    </form>
                    <div className="loginBtnGroup" onClick={() => doLogin()}>
                        <div className="loginBtn">로그인</div>
                    </div>                
                </div>
            </div>           
    )
}