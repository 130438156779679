import {SiseDto} from "../dto/Dto";
import {useEffect, useRef, useState} from "react";
import {convertBaseYm, convertBaseYmPlus, convertToLocalString} from "../utils/Utils";
import Highcharts, {Chart} from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";

interface Props {
  siseList: SiseDto[]
}

export default function AreaChart({siseList}: Props) {

  const chartRef = useRef<{
    chart: Chart;
    container: React.RefObject<HTMLDivElement>;
  }>(null);
  const [chartOption, setChartOption] = useState<object>({});

  // 프린트 시, 동작하는 addEventListener
  useEffect(() => {
    let mql = window.matchMedia("print");

    mql.addEventListener("change", () => changeWidth(mql));
    return () => mql.removeEventListener("change", () => changeWidth(mql));
  }, [])

  useEffect(() => {
    if (siseList.length === 0) return;

    const categoryList = getDateFromSise();
    const xAxisTickPositions = getTickPosition(categoryList);
    const dataSet = makeDataSet(categoryList);
    const dataFillNa = setDataFillNa(dataSet);
    const zones = getZonesModifiedByNull(dataSet);

    setChartOption({
      title: {
        text: null
      },
      subtitle: {
        text: `(데이터기준월 : ${categoryList[categoryList.length - 1]})`,
        align: 'right',
        style: {
          fontSize: '15px'
        },
        x: -20,
        y: 44
      },
      chart: {
        type: 'area',
        spacingLeft: 0,
        spacingRight: 0,
        width: 1200,
        height: 500,
        plotBorderWidth: 1
      },
      xAxis: {
        categories: categoryList,
        lineWidth: 0,
        crosshair: {
          color: 'grey',
          dashStyle: 'Dash',
          width: 1.5
        },
        labels: {
          align: 'right',
          style: {
            fontSize: '1.5rem',
          },
        },
        gridLineWidth: 1,
        tickColor: '#CACFDE',
        tickInterval: 6,
        tickPosition: 'outside',
        tickWidth: 1.5,
        tickLength: 0,
        tickPositions: xAxisTickPositions
      },
      yAxis: {
        lineWidth: 0,
        title: {
          align: 'high',
          offset: 0,
          text: '(단위:천원)',
          style: {
            fontSize: '15px'
          },
          rotation: 0,
          y: -15
        },
        tickAmount: 6,
        gridLineDashStyle: 'longDash',
        labels: {
          align: 'right',
          format: '{value:.,0f}',
          floating: false,
          x: 0,
          // useHTML: true,
          style: {
            minWidth: '2.2rem',
            paddingLeft: '0.32rem',
            paddingRight: '0.32rem',
            marginLeft: '1rem',
            marginRight: '1rem',
            textAlign: 'right',
            fontSize: '1.5rem',
            zIndex: '0'
          },
        }
      },
      plotOptions: {
        series: {
          animation: false,
          events: {
            legendItemClick: function () {
              return false;
            },
          },
        },
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, 'rgba(0, 102, 226, 0.4)'],
              [1, 'rgba(0, 102, 226, 0)']
            ]
          },
          marker: {
            radius: 3,
          },
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 2
            }
          },
          threshold: null
        }
      },
      legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'top',
        useHTML: false,
        itemStyle: {
          lineHeight: '1.4rem',
          fontSize: '1.7rem',
        },
        itemMarginTop: 0,
        itemMarginBottom: 0,        // 각 범례 항목의 픽셀 아래쪽 여백입니다.
        padding: 0,
        x: 0,
        y: 0,
      },
      tooltip: {
        // shared: true,
        // backgroundColor: null,
        // borderWidth: 1,
        shadow: true,
        useHTML: true,
        outside: true,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          return `
                        <div>
                            <div class="fs12">${this.x}</div>
                            <div class="areaTooltipBody fs15">
                                <div class='highcharts-tooltip-symbol fs20' style='color:${this.point.color}'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none">
                                    <circle cx="6" cy="7" r="5" fill="${this.point.color}" stroke="black" stroke-width="1" />
                                </svg>
                                </div>
                                <div class=${this.point.color == 'rgba(0, 0, 0, 0)' ? "hide" : ""}>${this.point.series.name}</div>
                                <div class=${this.point.color == 'rgba(0, 0, 0, 0)' ? "hide" : ""}>:</div>
                                <div class=${this.point.color == 'rgba(0, 0, 0, 0)' ? "" : "bold"}>${this.point.color == 'rgba(0, 0, 0, 0)' ? '데이터결측값' : convertToLocalString(Number(this.point.y))}</div>
                            </div>
                        </div>
                    `;
        },
        style: {
          backgroundColor: '#FFFFFF',
          zIndex: '10'
        }
      },
      series: [{
        name: '시세',
        data: dataFillNa,
        zoneAxis: 'x',
        zones: zones,
        legendSymbol: 'rectangle',
        color: '#0066E2',
        marker: {
          enabled: false,
          symbol: 'round',
          radius: 3
        }
      }]
    })

  }, [siseList])

  // x축 라벨 표시 포지션 생성 함수
  const getTickPosition = (categoryList: string[]) => {
    let resTickPositions = [];

    for (let i = categoryList.length - 1; i >= 0; i -= 6) {
      resTickPositions.push(i);
    }

    return resTickPositions;
  }

  // 2년치 basYm 생성
  const getDateFromSise = () => {
    let basYmList: string[] = [];
    let monthList = [];
    // console.log(siseList);
    const basYm = siseList[0].standardYm;
    const year = Number(basYm.substring(0, 4));
    const month = Number(basYm.substring(4, 6));
    // debugger
    for (let i = 1; i <= 24; i++) {
      monthList.unshift(month - i);
    }
    // console.log(monthList);

    monthList.map((mon, index) => {
      if (mon <= 0) {
        let tmpMon = mon - (Math.floor(mon / 12) * 12);
        let monStr = ('0' + tmpMon).slice(-2);
        let fixedToBasYm = (year + Math.floor(mon / 12)) + monStr;
        // 00월 나오는 경우 처리하기 위해 mon -1은 위쪽 for문에서 처리한 후, 문자에서 +1 처리
        basYmList.push(convertBaseYmPlus(fixedToBasYm));
      } else {
        let tmpMon = mon;
        let monStr = ('0' + tmpMon).slice(-2);
        let fixedToBasYm = year + monStr;
        // 00월 나오는 경우 처리하기 위해 mon -1은 위쪽 for문에서 처리한 후, 문자에서 +1 처리
        basYmList.push(convertBaseYmPlus(fixedToBasYm));
      }
    })
    // console.log(basYmList);
    return basYmList;
  }

  // chart data로 가공하기 위한 dataSet 함수
  const makeDataSet = (categoryList: string[]) => {
    let dataSet: number[] = [];

    categoryList.map((category) => {
      let marketPrice = 0;

      siseList.map((siseInfo) => {
        if (category === convertBaseYm(siseInfo.standardYm)) {
          marketPrice = siseInfo.marketPrice;
        }
      })

      dataSet.push(marketPrice);
    })

    return dataSet;
  }

  // dataSet을 통해 chart data 생성
  const setDataFillNa = (dataSet: number[]) => {
    let resData: object[] = [];
    let modified: number[] = [];

    for (let i = 0; i < dataSet.length; i++) {
      let aver = 0;
      let indexEnd = i;

      if (dataSet[i] === 0) {
        for (var j = i + 1; j < dataSet.length; j++) {
          if (dataSet[j] != 0) {
            indexEnd = j;
            break;
          }
        }

        if (indexEnd === i) {
          aver = i === 0 ? dataSet[i] : dataSet[i - 1];
          indexEnd = dataSet.length
        } else {
          aver = i === 0 ? dataSet[indexEnd] : ((dataSet[i - 1] + dataSet[indexEnd]) / 2);
        }

        for (let j = i; j < indexEnd; j++) {
          modified.push(aver);
        }

        i = indexEnd;
        modified.push(dataSet[i]);
      }
      else {
        modified.push(dataSet[i]);
      }
    }

    // debugger;

    dataSet.map((d, index) => {
      if (d === 0) {
        resData.push({
          y: modified[index],
          marker: {
            fillColor: '#FFFFFF',
            lineWidth: 1,
            lineColor: '#a0a0a0',
            radius: 4
          },
        })
      } else {
        resData.push({
          y: d,
          marker: {
            fillColor: '#0066E2'
          }
        })
      }
    })

    return resData;
  }

  // 라인 그리는 함수 (데이터가 없으면 라인 X, 데이터가 있을 경우 라인 O)
  const getZonesModifiedByNull = (data: number[]) => {
    let zones = [];

    for (var i = 1; i < data.length; i++) {
      if (data[i] == 0) {
        if (data[i - 1] == 0) {
          zones.push({
            value: i,
            color: 'rgba(0, 0, 0, 0)'
          });
        } else {
          zones.push({
            value: i - 0.8,
            dashStyle: 'Solid',
          });
          zones.push({
            value: i,
            color: 'rgba(0, 0, 0, 0)'
          });
        }
      } else {
        if (data[i - 1] == 0) {
          zones.push({
            value: i - 0.2,
            color: 'rgba(0, 0, 0, 0)'
          });
          zones.push({
            value: i,
            dashStyle: 'Solid',
          });
        } else {
          zones.push({
            value: i,
            dashStyle: 'Solid',
          });
        }
      }
    }
    return zones;
  }

  // 프린트할 때 차트 size 재조정하는 함수
  const changeWidth = (mql: MediaQueryList) => {

    // let mql = window.matchMedia("print");
    if (mql.matches) {
      if (chartRef.current) {
        chartRef.current.chart.setSize(1000, 500, false);
      }
    } else {
      if (chartRef.current) {
        chartRef.current.chart.setSize(1200, 500, false);
      }
    }
  }

  return (
      <div className="areaChart">
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOption}
            ref={chartRef}
        />
      </div>
  );
}