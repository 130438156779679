import axios from "axios";
import {AptDto, HistDto, InquiryDto, SiseDto} from "dto/Dto";

const hostUrl = process.env.REACT_APP_BACKEND_SERVER_URL;

/**
 * 아파트 정보 조회
 * @param YupMyeonDongDto 읍면동 정보
 * @returns AptDto[]
 */
function get단지동호(pnu: string) {
  let accessToken = sessionStorage.getItem("accessToken");
  return axios.get(hostUrl + "/api/v1/apt?pnu=" + pnu, {
    headers: {
      "Content-Type": 'application/json',
      Authorization: 'Bearer ' + accessToken
    }
  }).then((res) => {
    return res.data;
  }).catch(err => {
    return Promise.reject(err);
  })
}

/**
 * 시세/거래 정보 조회
 * @returns SiseDto[]
 */
function get시세거래(jpkDTO: AptDto) {
  let accessToken = sessionStorage.getItem("accessToken");
  return axios.post(hostUrl + "/api/v1/sise", {
    jpkDTO: jpkDTO,
    userId: sessionStorage.getItem('userId'),
  }, {
    headers: {
      "Content-Type": 'application/json',
      Authorization: 'Bearer ' + accessToken
    }
  }).then((res) => {
    return res.data;
  }).catch(err => {
  })
}

function get본건거래(siseDto: SiseDto) {
  let accessToken = sessionStorage.getItem("accessToken");
  return axios.post(hostUrl + "/api/v1/mainTrade", {
    baseYm: siseDto.standardYm,
    pnu: siseDto.pnu
  }, {
    headers: {
      "Content-Type": 'application/json',
      Authorization: 'Bearer ' + accessToken
    }
  }).then((res) => {
    return res.data;
  }).catch(err => {
  })
}

/**
 * 유저 IP 조회
 * @returns
 */
function findIp() {
  return axios.get('https://geolocation-db.com/json/').then((res) => {
    return res.data.IPv4;
  });
}

/**
 * 접속 이력 저장
 * @param hist
 * @returns
 */
function postUserHist(hist: HistDto) {
  let accessToken = sessionStorage.getItem("accessToken");
  return axios.post(hostUrl + "/api/v1/insertHist", {
    userIp: hist.userIp,
    userId: hist.userId,
    jpk: hist.jpk
  }, {
    headers: {
      "Content-Type": 'application/json',
      Authorization: 'Bearer ' + accessToken
    }
  });
}

/**
 * 문의하기 요청
 * @param inquiry
 * @returns
 */
function postInquiry(inquiry: InquiryDto) {
  let accessToken = sessionStorage.getItem("accessToken");
  return axios.post(hostUrl + "/api/v1/inquiry", {
    email: inquiry.email,
    message: inquiry.message
  }, {
    headers: {
      "Content-Type": 'application/json',
      Authorization: 'Bearer ' + accessToken
    }
  });
}

function get주소정보(keyword: string) {
  let accessToken = sessionStorage.getItem("accessToken");
  return axios.get(hostUrl + "/api/v1/address?keyword=" + keyword, {
    headers: {
      "Content-Type": 'application/json',
      Authorization: 'Bearer ' + accessToken
    }
  }).then((res) => {
    return res.data;
  }).catch(err => {
    return Promise.reject(err);
  });
}

export {get단지동호, get시세거래, get본건거래, findIp, postUserHist, postInquiry, get주소정보}