import '../css/modal.css';
import {AddressDto} from "../dto/Dto";
import btnClose from "../assets/close_btn.svg";
import {useEffect} from "react";

// Props 인터페이스 정의
interface AddressProps {
  addressList: AddressDto[];
  setOpenAddressModal: React.Dispatch<React.SetStateAction<boolean>>;
  setPnu: React.Dispatch<React.SetStateAction<string>>;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  setResult: React.Dispatch<React.SetStateAction<string>>;
}

export default function AddressModal(props: AddressProps) {

  const handleItemClick = (item: AddressDto) => {
    props.setPnu(item.outPnu);
    props.setOpenAddressModal(false);
    props.setKeyword(item.outLandNumberAddress);
    props.setResult(item.outLandNumberAddress);
  };

  // ESC 누르면 닫히게
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        props.setOpenAddressModal(false);
      }
    }
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  })

  return (
    <div className="modal-container">
      <div className="modal">
        <img className="modal-close" src={btnClose} onClick={() => props.setOpenAddressModal(false)} alt="modal close"
             title="창 닫기"/>
        <div className="popup-body h45 mb3r">
          <div className="bold">조회할 주소를 선택해 주십시오.</div>
          <hr/>
          <div className="result-list">
            {props.addressList.length === 0 ? (
              <p>주소가 없습니다.</p>
            ) : (
              props.addressList.map((item) => (
                <div className="search-result" key={item.outPnu} onClick={() => handleItemClick(item)}>
                  {item.outLandNumberAddress}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  )
}