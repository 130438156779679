import logo from '../assets/icon.svg'

interface Props {
    title: string
}

export default function ItemTitle ({
                                      title
                                  }: Props) {
    return (
        <div className="itemTitle">
            <div className="beforeImg"><img src={logo} /></div>
            <div className="titleBody fs24 bold">{title}</div>
        </div>
    );
}