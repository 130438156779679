import {useEffect, useState} from "react";

interface Props {
    lat: number,
    lng: number
}

export default function NaverMap ({
    lat,
    lng
}: Props) {
    const { naver } = window;
    const [map, setMap] = useState<naver.maps.Map | null>(null);

    // 프린트 시, 동작하는 addEventListener
    useEffect(()=> {
        let mql = window.matchMedia("print");

        mql.addEventListener("change", () => changeWidth(mql));
        return () => mql.removeEventListener("change", () => changeWidth(mql));
    },[])

    useEffect(()=> {
        if (lat === 0 || lng === 0) return;
        const mapOptions: naver.maps.MapOptions = {
            //center 옵션에 생성한 지도 중심 인스턴스 넣기
            center: new naver.maps.LatLng(lat, lng),
            zoom: 16,
            minZoom: 8,
            maxZoom: 19,
            zoomControl: true,
            zoomControlOptions: {
                style: naver.maps.ZoomControlStyle.SMALL,
                position: naver.maps.Position.TOP_RIGHT,
            },
            mapDataControl: false,
            scaleControl: true,
        };
        let newMap = new naver.maps.Map('map',mapOptions);
        createMarker(newMap);
        setMap(newMap);
    }, [lat, lng])

    const createMarker = (newMap:any) => {
        const markerOptions = {
            position: new naver.maps.LatLng(lat, lng),
            map: newMap,
            zIndex: 1001,
            icon: {
                url: '/marker.svg',
                // size: new naver.maps.Size(27, 41),
                // origin: new naver.maps.Point(0, 0),
                // anchor: new naver.maps.Point(11, 35)
            },
        };

        let newMarker = new naver.maps.Marker(markerOptions);

        newMarker.setMap(newMap);
    }

    const changeWidth = (mql:MediaQueryList) => {
        if (mql.matches) {
            let size = new naver.maps.Size(1000, 500);
            if (map) {
                map.setSize(size);
            }
        } else {
            let size = new naver.maps.Size(1200, 500);
            if (map) {
                map.setSize(size);
            }
        }
    }

    return (
        <div>
            <div id="map" className="naverMap"></div>
        </div>
    );
}